define("discourse/plugins/discourse-motley-fool/discourse-motley-fool/templates/connectors/groups-form-membership-below-automatic/accessible-service-id", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="control-group">
    <label for="motley-fool-auto-assign">
      {{i18n "admin.accessible_services_desc"}}
    </label>
  
    {{input
      type="text"
      value=model.motley_fool_service_id
      class="motley-fool-accessible-service input-xxlarge"
    }}
  </div>
  */
  {
    "id": "PNHZEUAh",
    "block": "[[[10,0],[14,0,\"control-group\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,\"for\",\"motley-fool-auto-assign\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"admin.accessible_services_desc\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[1,[28,[35,1],null,[[\"type\",\"value\",\"class\"],[\"text\",[30,0,[\"model\",\"motley_fool_service_id\"]],\"motley-fool-accessible-service input-xxlarge\"]]]],[1,\"\\n\"],[13],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/discourse-motley-fool/discourse-motley-fool/templates/connectors/groups-form-membership-below-automatic/accessible-service-id.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"i18n\",\"input\"]]",
    "moduleName": "discourse/plugins/discourse-motley-fool/discourse-motley-fool/templates/connectors/groups-form-membership-below-automatic/accessible-service-id.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});