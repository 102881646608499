define("discourse/plugins/discourse-motley-fool/discourse/initializers/motley-fool-save-accessible-id", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "motley-fool-save-accessible-id",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.11.5", api => {
        api.modifyClass("model:group", {
          pluginId: "discourse-motley-fool",
          asJSON() {
            const attrs = this._super(...arguments);
            attrs["custom_fields"] = {
              motley_fool_service_id: this.motley_fool_service_id
            };
            return attrs;
          }
        });
      });
    }
  };
});